import { required, sameAs, minLength } from 'vuelidate/lib/validators'
import ZbEditProfileModal from './edit-profile'
import ZbBankAccountTab from './bank-account-tab'
import { mapState, mapActions, mapGetters } from 'vuex'
import ZbUserProfileMixin from './ZbUserProfileMixin'
import { ServiceFactory } from '@/services/ServiceFactory'
const SettingsService = ServiceFactory.get('SettingsService')

export default {
  name: 'ZbBarProfileComponent',
  mixins: [ ZbUserProfileMixin ],
  components: {
    ZbEditProfileModal,
    ZbBankAccountTab
  },
  data () {
    return {
      dialog: false,
      selectedLang: localStorage.getItem('currentLang') || 'es',
      availableLangs: [],
      tab: null,
      currPassword: null,
      newPassword: null,
      repeatPassword: null
    }
  },
  validations: {
    currPassword: { required },
    newPassword: {
      required,
      minLength: minLength(8)
    },
    repeatPassword: {
      required,
      minLength: minLength(8),
      sameAsPassword: sameAs('newPassword')
    }
  },
  computed: {
    currPasswordErr () {
      const errors = []
      if (!this.$v.currPassword.$dirty) return errors
      !this.$v.currPassword.required && errors.push(this.$t('validator.required', { A: this.$t('barProfile.currPassword') }))
      return errors
    },
    newPasswordErr () {
      const errors = []
      if (!this.$v.newPassword.$dirty) return errors
      !this.$v.newPassword.required && errors.push(this.$t('validator.required', { A: this.$t('barProfile.newPassword') }))
      !this.$v.newPassword.minLength && errors.push(this.$t('validator.minLength', { A: 8 }))
      return errors
    },
    repeatPasswordErr () {
      const errors = []
      if (!this.$v.repeatPassword.$dirty) return errors
      !this.$v.repeatPassword.required && errors.push(this.$t('validator.required', { A: this.$t('barProfile.repeatPassword') }))
      !this.$v.repeatPassword.minLength && errors.push(this.$t('validator.minLength', { A: 8 }))
      !this.$v.repeatPassword.sameAsPassword && errors.push(this.$t('validator.sameAsPassword', { A: this.$t('barProfile.newPassword'), B: this.$t('barProfile.repeatPassword') }))
      return errors
    },
    ...mapState('settingsModule', ['dataSettings']),
    ...mapGetters('settingsModule', [
      'titleBar$',
      'bannerBarUrl$',
      'profileBarUrl$',
      'location$',
      'slogan$',
      'telephone$',
      'socialMedia$',
      'workingSchedule$',
      'maxPeopleCapacity$',
      'address$'
    ]),
    ...mapGetters({
      typeBusiness$: 'settingsModule/typeBusiness',
      currentLang$: 'langModule/currentLang'
    })
  },
  created: function () {
    this.translateDropdown(this.currentLang$)
  },
  watch: {
    selectedLang: {
      handler: function (newVal) {
        this.translateDropdown(newVal)
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('langModule', ['setLang']),
    goToRouteFirst () {
      this.$router.push({ path: '/admin/profile' }).catch(() => {})
    },
    selectLang () {
      const currentLang = this.selectedLang
      this.setLang({ currentLang })
      this.$i18n.locale = this.currentLang$
      this.$vuetify.lang.current = this.currentLang$
    },
    translateDropdown (locale) {
      this.availableLangs = [
        {
          value: this.$i18n.t('general.en', locale),
          id: 'en'
        },
        {
          value: this.$i18n.t('general.es', locale),
          id: 'es'
        }
      ]
    },
    changePassword () {
      const postData = {
        currPassword: this.currPassword,
        nextPassword: this.newPassword,
        verifyNextPassword: this.repeatPassword
      }
      this.$v.$touch()
      if (!this.$v.$invalid) {
        SettingsService.updatePassword(postData)
          .then(resp => {
            this.$notify({
              title: 'Bien!',
              type: 'success',
              text: resp.msg
            })
            this.$v.$reset()
            this.currPassword = null
            this.newPassword = null
            this.repeatPassword = null
          }, err => {
            this.$notify({
              title: 'Mal!',
              type: 'error',
              text: err.msg
            })
            this.$v.$reset()
            this.currPassword = null
            this.newPassword = null
            this.repeatPassword = null
          })
      }
    }
  }
}
