import { required, numeric } from 'vuelidate/lib/validators'

export default {
  data () {
    return {}
  },
  validations: {
    dataBankAccount: {
      bankAccount: {
        ownerName: {
          required
        },
        bankName: {
          required
        },
        accountType: {
          required
        },
        accountNumber: {
          required,
          numeric
        },
        moneyCurrency: {
          required
        },
        documentType: {
          required
        },
        documentNumber: {
          required,
          numeric
        },
        description: {}
      }
    }
  },
  computed: {
    ownerNameErrors () {
      const errors = []
      if (!this.$v.dataBankAccount.bankAccount.ownerName.$dirty) return errors
      !this.$v.dataBankAccount.bankAccount.ownerName.required && errors.push(this.$t('validator.required', { field: this.$t('bankAccount.ownerName') }))
      return errors
    },
    bankNameErrors () {
      const errors = []
      if (!this.$v.dataBankAccount.bankAccount.bankName.$dirty) return errors
      !this.$v.dataBankAccount.bankAccount.bankName.required && errors.push(this.$t('validator.required', { field: this.$t('bankAccount.bankName') }))
      return errors
    },
    accountTypeErrors () {
      const errors = []
      if (!this.$v.dataBankAccount.bankAccount.accountType.$dirty) return errors
      !this.$v.dataBankAccount.bankAccount.accountType.required && errors.push(this.$t('validator.required', { field: this.$t('bankAccount.accountType') }))
      return errors
    },
    accountNumberErrors () {
      const errors = []
      if (!this.$v.dataBankAccount.bankAccount.accountNumber.$dirty) return errors
      !this.$v.dataBankAccount.bankAccount.accountNumber.required && errors.push(this.$t('validator.required', { field: this.$t('bankAccount.accountNumber') }))
      !this.$v.dataBankAccount.bankAccount.accountNumber.numeric && errors.push(this.$t('validator.numeric', { field: this.$t('bankAccount.accountNumber') }))
      return errors
    },
    moneyCurrencyErrors () {
      const errors = []
      if (!this.$v.dataBankAccount.bankAccount.moneyCurrency.$dirty) return errors
      !this.$v.dataBankAccount.bankAccount.moneyCurrency.required && errors.push(this.$t('validator.required', { field: this.$t('bankAccount.moneyCurrency') }))
      return errors
    },
    documentTypeErrors () {
      const errors = []
      if (!this.$v.dataBankAccount.bankAccount.documentType.$dirty) return errors
      !this.$v.dataBankAccount.bankAccount.documentType.required && errors.push(this.$t('validator.required', { field: this.$t('bankAccount.documentType') }))
      return errors
    },
    documentNumberErrors () {
      const errors = []
      if (!this.$v.dataBankAccount.bankAccount.documentNumber.$dirty) return errors
      !this.$v.dataBankAccount.bankAccount.documentNumber.required && errors.push(this.$t('validator.required', { field: this.$t('bankAccount.documentNumber') }))
      !this.$v.dataBankAccount.bankAccount.documentNumber.numeric && errors.push(this.$t('validator.numeric', { field: this.$t('bankAccount.documentNumber') }))
      return errors
    },
    descriptionErrors () {}
  }
}
