import { ServiceFactory } from '@/services/ServiceFactory'
const SettingsProfileService = ServiceFactory.get('SettingsProfileService')

export default {
  data () {
    return {
      itemsGender: [
        {
          value: 'Masculino',
          text: this.$i18n.t('barProfile.male')
        },
        {
          value: 'Femenino',
          text: this.$i18n.t('barProfile.female')
        }
      ],
      profileSettings: {
        name: null,
        image: null,
        username: null,
        gender: null,
        createdAt: null,
        updatedAt: null,
        previewFile: null,
        auxImage: null
      }
    }
  },
  watch: {
    tab () {
      if (this.tab === 'tab-2') {
        this.initializeProfile()
      }
    }
  },
  methods: {
    async initializeProfile () {
      try {
        const { settings, success } = await SettingsProfileService.get()
        if (await success) {
          this.profileSettings = await { ...this.profileSettings, ...settings }
          if (await this.profileSettings.image) {
            this.profileSettings.previewFile = await this.profileSettings.image
            this.profileSettings.auxImage = await this.profileSettings.image
            this.profileSettings.image = null
          }
        }
      } catch (error) {
        this.$notify({
          title: 'Error!!',
          type: 'error',
          text: error.msg
        })
      }
    },
    async updateUserProfile () {
      await this.saveImageProfileFormData()
      try {
        const { settings, success, msg } = await SettingsProfileService.put({
          username: this.profileSettings.username,
          name: this.profileSettings.name,
          gender: this.profileSettings.gender
        })
        if (await success) {
          this.$notify({
            title: 'Exito:',
            type: 'success',
            text: msg
          })
          this.profileSettings = await { ...this.profileSettings, ...settings }
          if (await this.profileSettings.image) {
            this.profileSettings.previewFile = await this.profileSettings.image
            this.profileSettings.auxImage = await this.profileSettings.image
            this.profileSettings.image = null
          }
        }
      } catch (error) {
        this.$notify({
          title: 'Error!!',
          type: 'error',
          text: error.msg
        })
      }
    },
    async saveImageProfileFormData () {
      if (this.profileSettings.image) {
        const postData = { image: this.profileSettings.image }
        try {
          const { settings, msg, success } = await SettingsProfileService.postImageProfileFormData(postData)
          if (success) {
            this.profileSettings.previewFile = await settings.image
            this.$notify({
              title: 'Exito:',
              type: 'success',
              text: msg
            })
          }
        } catch (error) {
          this.$notify({
            title: 'Error!!',
            type: 'error',
            text: error.msg
          })
        }
      }
    },
    async onFileChange (e) {
      if (e) {
        const file = e
        this.profileSettings.previewFile = URL.createObjectURL(file)
      } else {
        this.profileSettings.previewFile = this.profileSettings.auxImage
      }
    }
  }
}
