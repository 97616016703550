import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'zb-edit-profile-modal',
  data () {
    return {
      imageProfile: null,
      imageBanner: null,
      loading: false,
      preview: {
        profile: null,
        banner: null
      }
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  watch: {
    value () {
      this.preview = {
        profile: null,
        banner: null
      }
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'fas fa-trash'
    }
  },
  computed: {
    ...mapGetters('settingsModule', [
      'bannerBarUrl$',
      'profileBarUrl$'
    ])
  },
  methods: {
    ...mapActions('settingsModule', ['imageProfileUpload', 'imageBannerUpload', 'updateProfile']),
    async submitEditProfile () {
      this.loading = true
      // Image profile
      if (this.imageProfile && this.imageProfile[0]) {
        const newImage = new FormData()
        newImage.append('profile', this.imageProfile[0])
        await this.imageProfileUpload(newImage)
      }
      // Image banner
      if (this.imageBanner && this.imageBanner[0]) {
        const newImage2 = new FormData()
        newImage2.append('banner', this.imageBanner[0])
        await this.imageBannerUpload(newImage2)
      }
      this.dialogEvent(this.edited)
    },
    dialogEvent () {
      this.loading = false
      this.preview = {
        profile: null,
        banner: null
      }
      this.$emit('input')
    },
    handleProfileUpload (e) {
      this.imageProfile = e.target.files
      this.previewProfile(e)
    },
    handleBannerUpload (e) {
      this.imageBanner = e.target.files
      this.previewBanner(e)
    },
    previewProfile (event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.preview.profile = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    },
    previewBanner (event) {
      const input = event.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.preview.banner = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      }
    }
  }
}
