import ZbBankAccountMixin from './ZbBankAccountMixin'
import { ServiceFactory } from '@/services/ServiceFactory'
const BankAccountService = ServiceFactory.get('BankAccountService')

export default {
  name: 'ZbBankAccountTab',
  mixins: [ ZbBankAccountMixin ],
  data () {
    return {
      dataBankAccount: {
        success: false
      },
      bankNameItems: [
        'Banco Nacional de Bolivia',
        'Banco Mercantil de Santa Cruz',
        'Banco Central de Bolivia',
        'Banco de Crédito de Bolivia',
        'Banco Bisa S.A',
        'Banco Unión S.A',
        'Banco Económico',
        'Banco Solidario S.A',
        'Banco Ganadero',
        'Banco Fie',
        'Banco Fassil S.A',
        'Banco Fortaleza'
      ],
      accountTypeItems: [
        'Caja de ahorro',
        'Cuenta corriente',
        'Tarjeta de credito',
        'Préstamo',
        'Billetera móvil'
      ],
      moneyCurrencyItems: [
        'Bolivianos',
        'Dólares'
      ],
      documentTypeItems: [
        'NIT',
        'Cédula de indentidad',
        'Pasaporte',
        'C. Extranjero',
        'CI. Agregada'
      ]
    }
  },
  mounted () {
    this.getBankAccount()
  },
  methods: {
    async getBankAccount () {
      try {
        const data = await BankAccountService.get()
        this.dataBankAccount = await data
        if (await this.dataBankAccount.bankAccount === null) {
          this.dataBankAccount = {
            ...this.dataBankAccount,
            bankAccount: {
              ownerName: null,
              bankName: null,
              accountType: null,
              accountNumber: null,
              moneyCurrency: null,
              documentType: null,
              documentNumber: null,
              description: null
            }
          }
        }
      } catch (error) {
        this.dataBankAccount = error
        this.$notify({
          title: 'Error!!',
          type: 'error',
          text: error.msg
        })
      }
    },
    async updateBankAccount () {
      const { bankAccount } = await this.dataBankAccount
      const dataUpdate = {
        ownerName: bankAccount.ownerName,
        bankName: bankAccount.bankName,
        accountType: bankAccount.accountType,
        accountNumber: bankAccount.accountNumber,
        moneyCurrency: bankAccount.moneyCurrency,
        documentType: bankAccount.documentType,
        documentNumber: bankAccount.documentNumber,
        description: bankAccount.description
      }
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const response = await BankAccountService.put(dataUpdate)
          this.dataBankAccount = response
          this.$notify({
            title: 'Bien!!',
            type: 'success',
            text: 'Datos actualizados'
          })
        } catch (error) {
          this.$notify({
            title: 'Error!!',
            type: 'error',
            text: error.message
          })
        }
      }
    }
  }
}
